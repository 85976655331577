<template>
  <div>
    <w-top-bar>Inscripciones {{ product.name }}</w-top-bar>
    <div class="h-full p-5 bg-gray-200">
      <div class="flex items-center justify-end h-12 px-1">
        <button
          @click="reload()"
          class="
            flex
            items-center
            text-sm text-blue-500
            cursor-pointer
            hover:text-blue-600
          "
        >
          <svg
            v-if="downloading"
            class="w-3 h-3 mr-1 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>

          <svg
            v-else
            class="w-3 h-3 mr-1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
            />
          </svg>

          Descargar Planilla
        </button>
      </div>
      <w-inscriptions :items="inscriptions" />
    </div>
  </div>
</template>

<script>
import { makeFindMixin, makeGetMixin } from "feathers-vuex";
const apiURL =
  process.env.NODE_ENV === "production"
    ? "https://api.orsai.org/"
    : "http://localhost:3030/";
export default {
  name: "inscriptionsList",
  data() {
    return {
      downloading: false,
    };
  },
  mixins: [
    makeFindMixin({ service: "inscriptions" }),
    makeGetMixin({ service: "products" }),
  ],
  computed: {
    productId() {
      return this.$route.params.id;
    },
    productParams() {
      return {
        query: {
          $select: ["productIds", "name"],
        },
      };
    },
    inscriptionsParams() {
      return {
        query: {
          // productId: {$in: [this.productId, ...this.product.productIds]},
          productId: this.productId,
          // order: {
          //   $ne: null
          // },
          $sort: {
            createdAt: -1,
          },
        },
      };
    },
    // inscriptionsFetchParams() {

    //   if(this.product){

    //   return {
    //     query: {
    //       productId: {$in: [...this.product.productIds]},
    //       $sort: {
    //         createdAt: -1,
    //       },
    //     },
    //   };
    //   }

    //   return {
    //     query: {
    //       $sort: {
    //         createdAt: -1,
    //       },
    //     },
    //   };
    // }
  },
  methods: {
    reload() {
      console.log("reload");
      this.findInscriptions();
    },
    async download() {
      console.log("download");
      let fileName = this.product.name.replace(/\s+/g, "-").toLowerCase();
      this.downloading = true;
      this.axios
        .get(`${apiURL}inscriptions`, {
          responseType: "blob",
          params: {
            $limit: -1,
            productId: this.$route.params.id,
            $select: ["customerId", "customer", "orderId", "order"],
          },
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `inscripciones-${fileName}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.downloading = false;
        })
        .catch(() => {
          this.downloading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
